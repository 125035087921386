@import './color.scss';


* {
  box-sizing: border-box;
}

// font-family: 'Open Sans', sans-serif;
html {
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  line-height: 1.6;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

body {
  margin: 0;
}

h1 {
  font-size: 3em;
  color: #000;
  font-weight: 500;
}

h2 {
  font-size: 1.5em;
  color: #000;
}

.regular-text{
  font-weight: 400;
}

.bold-text{
  font-weight: 700;
}

