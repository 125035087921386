$white: #fff;
//$black: #000;
$black: #282936;


$divider: #cbcbcb;

$light-grey: #AEAEAE;
$dark-grey: #7F7F7F;

$green: #1ECD97;
//$red: #EF2D56;
$red: #EF476F;
//$red: #FE5F55;

$purple: #7988ff;
$accent-blue: #01bea2;
$orange: #EC7263;

