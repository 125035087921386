.App {
}

.landing {
}
.landing {
  background-attachment: fixed;
  background-size: cover;
}

