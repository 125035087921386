@import './color.scss';


.expanded-section-container{
  max-width: 960px;
  margin: 0 auto; 
  padding-bottom: 80px;
  padding-top: 60px;
  padding-left: 40px;
  padding-right: 40px;
  color: $dark-grey;

  .back-button{
    color: $dark-grey;
    text-decoration: none;
    transition: 0.3s ease-out;

    &:hover {
        color: $black;
    }

  }

  .expanded-section-title{
    text-align: left;
    font-size: 2.24em;
    color: $black;
    margin-bottom: 20px;
  }

  .expanded-data-item{
    padding: 25px 0;

    a {
      color: inherit;
      text-decoration: none;
    }

    .expanded-item-contents {
      display: flex;
    }
  }
}

@media screen and (max-width: 600px) {
  .expanded-section-container{
    margin: 0 auto; 
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 30px;
    color: $dark-grey;
    .expanded-data-item .about{
      display: inline-block;
    }
  }
}


