@import './color.scss';

.section-container{
  max-width: 960px;
  margin: 0 auto; 
  padding-bottom: 80px;
  padding-top: 0px;
  padding-left: 80px;
  padding-right: 80px;
  color: $dark-grey;

  .section-title{
    text-align: left;
    font-size: 2.24em;
    color: $black;
    margin-bottom: 20px;
  }

  .data-item{
    margin: 35px 0;

    a {
      color: inherit;
      text-decoration: none;
    }

    .item-contents {
      display: flex;
    }
  }

  .learnmore-button{
    padding-top: 20px;
  
    .learn-btn-green{
      margin: 0, auto;
      outline:none;
      height: 60px;
      text-align: center;
      width: 160px;
      border-radius:40px;
  
      background: #fff;
      border: 2px solid $accent-blue;
      color:$accent-blue;
      letter-spacing:1px;
      text-shadow:0;
      font:{
        size:12px;
        weight:bold;
      }
      cursor: pointer;
      transition: all 0.25s ease;
      &:hover {
        color:white;
        background: $accent-blue;
      }
      &:active {
        //letter-spacing: 2px;
        letter-spacing: 2px ;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .section-container{
    margin: 0 auto; 
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 30px;
    color: $dark-grey;
  }

  .learnmore-button{
    padding-top: 10px;
  }
}


