@import './color.scss';

.data-image{
  height: 75px;
}

.inline{
  //display: inline-block;
  margin-right: 40px;
  vertical-align: middle;

  .data-heading {
    transition: all 0.15s ease-out;
    border-bottom: 1px solid rgba(0, 0, 0, 0);
    display: inline-block;
    margin: 4px 0;
    color: inherit;
  
    &:hover{
      transition: all .15s ease-out;
      border-bottom: 1px solid transparent;
      display: inline-block;
      border-bottom-color: #AEAEAE;
    }
  }

  .data-image{
    height: 75px;
  }

}


.data-container{
  margin: 0 auto;
  padding: 50px 0; 
}

.data-info{
  display: inline-block;
  height: 90px;
  vertical-align: middle;
}


.data-sub-heading {
    margin: 4px 0;
    color: inherit;
}

.project-sub-heading {
    margin: 4px 0;
    color: inherit;
}

.project {
  display: inline;
  /*
  color: $purple;
  */
}

.tech {
  display: block;
}

.string-accent{
  /*
  color: $accent-blue;
  */
  color: $purple;
}

@media screen and (min-width: 600px) {
  .mobile-project-sub-heading{
    display: none;
  }
}

@media screen and (max-width: 600px) {
  .inline{
    //display: inline-block;
    margin-right: 20px;
  }
  .project-sub-heading{
    display: none;
  }
}
