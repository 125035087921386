@import './color.scss';


.social-container{
  height: 200px;
  margin: 0 auto;
  border-top: 1px solid $divider;
  max-width: 600px;

  .social-list {
    list-style-type: none;
    display: table;
    margin: 0 auto;
    padding: 0;
    align-items: center;
    padding-top: 20px;

    li{
      display: table-cell;
      padding-left: 30px;
      padding-right: 30px;
    }
  }
}

svg:hover path { 
    fill: $black;
}

@media screen and (max-width: 600px) {
  .social-container {
    width: 80%;
    .social-list li{
      padding-left: 15px;
      padding-right: 15px;
    }
  }
}
